table {
    border-collapse:separate;
    border:solid grey 1px;
    border-radius:6px;
    position: relative;
}

@import url('./index.css');

.table thead tr td b {
  font-family: 'FuturaBook'; /* Use your defined font */
  color: "grey"; /* Set the desired font color */
}

.table thead:first-child::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px; /* Adjust height for desired shadow thickness */
    background-color: rgba(0, 0, 0, 0.01); /* Set shadow color and opacity */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    /* bottom: 0; Position at the bottom of the thead */
    left: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) /* Base shadow */,
              0px 0px 0px 1px rgba(0, 0, 0, 0.1) inset, /* Inner shadow for depth */
              0px 4px 6px 0px rgba(0, 0, 0, 0.1);
}

.table thead:first-child::after ~ tr {
    /* Any styles applied here will affect the element directly after */
    /* We'll use this to create the gradient effect */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), transparent);
}
  
select {
    border: none;
    border-bottom: 1px solid #ccc; /* Adjust color and thickness as needed */
}

input {
    border: none;
    border-bottom: 1px solid #ccc; /* Adjust color and thickness as needed */
    padding: 5px; /* Add some padding for usability (optional) */
}

.custom-button {
    border-radius: 4px; /* Set desired right border radius */
    position: relative; /* Enable positioning of pseudo-element */
    overflow: hidden; /* Clip overflowing content */
  }
  
.custom-button::before {
    content: ""; /* Empty content */
    position: absolute;
    top: 0;
    left: 0;
    width: 50%; /* Adjust width for desired coverage */
    height: 100%; /* Full button height */
    background-color: inherit; /* Inherit button background color */
    border-radius: 0 0 0 4px; /* Remove top, left, and bottom radius, keep right */
  }

/*
td, th {
    border-left:solid grey 1px;
    border-top:solid grey 1px;
}

th {
    background-color: blue;
    border-top: none;
}

td:first-child, th:first-child {
     border-left: none;
} */
  