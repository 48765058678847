body {
    height: 100%;
    background-image: linear-gradient(#FF4500 0% 30%, #FFFFFF 10% 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}
  
#searchInput::-ms-input-placeholder { /* Internet Explorer 10-11 */
color: black;
}

#searchInput::-ms-input-placeholder { /* Microsoft Edge */
color: black;
}

#searchInput {
    border: none;
    background-color: rgba(255, 255, 255, 1.0);
}

.floating-button {
    position: fixed; /* Fix to viewport */
    top: 10px; /* Adjust top position */
    left: 10px; /* Adjust left position */
    z-index: 100; /* Ensure button appears above other elements */
    border-radius: 50%;
    padding-top:9px;
    padding-bottom:9px;
    padding-left:13px;
    padding-right:13px;
    margin-left:5px;
    border: none;
    color:gray;
    background-color: white;
    /* Other button styles (background-color, border, etc.) */
  }