body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'FuturaBold';
  src: local('FuturaBold'), url(./fonts/FuturaBold.ttf) format('ttf');
}

@font-face {
  font-family: 'FuturaBook';
  src: local('FuturaBook'), url(./fonts/FuturaBook.ttf) format('ttf');
}

@font-face {
  font-family: 'FuturaRound';
  src: local('FuturaRound'), url(./fonts/FuturaRound.ttf) format('ttf');
}

@font-face {
  font-family: 'FuturaStdBook';
  src: local('FuturaStdBook'), url(./fonts/FuturaStdBook.ttf) format('ttf');
}

@font-face {
  font-family: 'FuturaStdHeavy';
  src: local('FuturaStdHeavy'), url(./fonts/FuturaStdHeavy.ttf) format('ttf');
}

@font-face {
  font-family: 'FuturaThin';
  src: local('FuturaThin'), url(./fonts/FuturaThin.ttf) format('ttf');
}

.heading {
  font-family: 'FuturaBold';
  font-size: 1.2rem;
}

.subHeading {
  font-family: 'FuturaBold';
  font-size: 1.1rem;
}

.paragraph {
  font-family: 'FuturaBook';
}

