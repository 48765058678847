#emailInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
}
  
#emailInput::-ms-input-placeholder { /* Internet Explorer 10-11 */
color: white;
}

#emailInput::-ms-input-placeholder { /* Microsoft Edge */
color: white;
}

#emailInput {
    border: none;
    background-color: rgba(0, 0, 0, 0.0);
}

#passwordInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
}
  
#passwordInput::-ms-input-placeholder { /* Internet Explorer 10-11 */
color: white;
}

#passwordInput::-ms-input-placeholder { /* Microsoft Edge */
color: white;
}

#passwordInput {
    border: none;
    background-color: rgba(0, 0, 0, 0.0);
}